var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sendCode-box" },
    [
      _c("iHeader", {
        attrs: {
          "show-back": false,
          title: "信息验证",
          theme: "light",
          background: "var(--themeColor)",
        },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _c("i-tips", { attrs: { tipsList: _vm.tipsList } }),
          _c(
            "div",
            { staticClass: "phone-box" },
            [
              _c("div", { staticClass: "phone-show" }, [
                _vm._v(_vm._s(_vm.phone.slice(0, 7))),
              ]),
              _c("i-password-input", {
                attrs: {
                  value: _vm.defaultVal,
                  length: 4,
                  gutter: 10,
                  mask: false,
                  focused: _vm.showKeyboard,
                },
                on: {
                  focus: function ($event) {
                    _vm.showKeyboard = true
                  },
                },
              }),
            ],
            1
          ),
          _c("i-field", {
            attrs: {
              center: "",
              clearable: "",
              label: "验证码",
              placeholder: "请输入验证码",
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c(
                      "i-button",
                      {
                        attrs: {
                          size: "small",
                          disabled: _vm.gettingCode,
                          color: "var(--themeColor)",
                        },
                        on: { click: _vm.beforeSend },
                      },
                      [
                        _vm.gettingCode
                          ? _c("span", [_vm._v(_vm._s(_vm.countDown) + "s")])
                          : _c("span", [_vm._v("发送验证码")]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.sms,
              callback: function ($$v) {
                _vm.sms = $$v
              },
              expression: "sms",
            },
          }),
          _c(
            "i-button",
            {
              staticStyle: { "margin-top": "0.7rem" },
              attrs: {
                round: "",
                color: "var(--themeColor)",
                block: "",
                loading: _vm.loadingBtn,
                "loading-text": "确认",
              },
              on: { click: _vm.toSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _c("i-number-keyboard", {
        attrs: { maxlength: "4", show: _vm.showKeyboard },
        on: {
          blur: function ($event) {
            _vm.showKeyboard = false
          },
        },
        model: {
          value: _vm.defaultVal,
          callback: function ($$v) {
            _vm.defaultVal = $$v
          },
          expression: "defaultVal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="sendCode-box">
        <iHeader :show-back="false" title="信息验证"
                 theme="light"
                 background="var(--themeColor)"></iHeader>
        <div class="cnt-box">
            <i-tips :tipsList="tipsList"></i-tips>
            <div class="phone-box">
                <div class="phone-show">{{ phone.slice(0,7) }}</div>
                <!-- 密码输入框 -->
                <i-password-input
                    :value="defaultVal"
                    :length="4"
                    :gutter="10"
                    :mask="false"
                    :focused="showKeyboard"
                    @focus="showKeyboard = true"
                />
            </div>
            <i-field
                v-model="sms"
                center
                clearable
                label="验证码"
                placeholder="请输入验证码"
            >
                <template #button>
                    <i-button size="small" :disabled="gettingCode" @click="beforeSend" color="var(--themeColor)">
                        <span v-if="gettingCode">{{ countDown }}s</span>
                        <span v-else>发送验证码</span>
                    </i-button>
                </template>
            </i-field>
            <i-button style="margin-top: 0.7rem" round
                      color="var(--themeColor)" block
                      @click="toSubmit"
                      :loading="loadingBtn" loading-text="确认">确认</i-button>
        </div>
        <!-- 数字键盘 -->
        <i-number-keyboard
            v-model="defaultVal"
            maxlength="4"
            :show="showKeyboard"
            @blur="showKeyboard = false"
        />
    </div>
</template>

<script>
import sendCode from "../../utils/sendCode";
import generic from "../../utils/generic";

export default {
    name: "SendCode",
    extends: sendCode,
    data() {
        return {
            tipsList: ['请输入您手机号码后四位，并点击发送验证码。收到验证码后请填写到下面输入框中。'],
            defaultVal: '',
            showKeyboard: false,
            sms: '',
            loadingBtn: false,
            config: {}
        }
    },
    created() {
        this.getConfig()
        this.phone = this.$smartStorage.get('mobile') || ''
        if(!this.phone) {
            this.$idialog
                .confirm({
                    title: "提示",
                    message: "服务器错误，请稍后重试！",
                })
                .then(() => {
                });
            return false
        }
    },
    methods: {
        getConfig() {
            generic.getConfigs({
                    group: this.$route.query.type || "Speaker",
                    router: this.$route.path })
                .then((config) => {
                    this.config = config
                    for (const configKey in config) {
                        if(config[configKey].callback) this[config[configKey].callback]()
                    }
                });
        },
        async toCheck() {
            let _config = generic.replaceEvalObject(this.config['beforeRouter'],
                { speakerId: this.$smartStorage.get('speakerId') || '' })
            const res = await this.$service.GenericPost(_config);
            if(res && res.success) {
                if(res.content && res.content.aggreementStatus == 2) {
                    this.$idialog
                        .confirm({
                            title: "提示",
                            message: "讲者协议已签署！",
                            showConfirmButton: false,
                            showCancelButton: false
                        })
                        .then(() => {
                        });
                }
            }
        },
        beforeSend() {
            if(this.defaultVal !== this.phone.slice(7)) {
                this.$itoast('请输入正确的手机号码！')
                return false
            }
            this.sendCode()
        },
        toSubmit() {
            if(!this.sms) {
                this.$itoast('请输入验证码！')
                return false
            }
            let params = {
                mobile: this.phone,
                captcha: this.sms,
                sessionId: this.sessionId
            }
            this.loadingBtn = true
            this.$service.CertifyCaptcha(params).then((res) => {
                if(res && res.success) {
                    this.$router.push({
                        name: 'agreement',
                        query: this.$route.query
                    })
                }
            })
            this.loadingBtn = false
        }
    }
}
</script>

<style lang="less" scoped>
.sendCode-box {
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    .cnt-box {
        padding: 0.2rem 0.18rem;
        box-sizing: border-box;
        .phone-box {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 0.1rem 0;
            .phone-show {
                font-size: 0.18rem;
                font-weight: bold;
            }
            .van-password-input {
                margin: unset;
            }
            .van-password-input__security {
                height: 0.4rem !important;
            }
            .van-password-input__security li {
                width: 0.4rem;
                height: 0.4rem;
                border: 1px solid #F5F5F5;
            }
        }
    }
}
</style>
